import './AppHeader.css';

import { ApplicationContext, Header } from '@elotech/components';
import { ReactComponent as Logo } from 'common/assets/oxy-logo.svg';
import React, { useContext } from 'react';

type Props = {
  showMenu: boolean;
};

const AppHeader: React.FC<Props> = props => {
  const { showMenu } = props;
  const { entidade } = useContext(ApplicationContext);
  return (
    <Header
      title={entidade?.nome || 'Minha Conta'}
      brasao={entidade?.brasao}
      showToggleButton={showMenu}
      logoModulo={Logo}
      logoOxy
    >
      <Header.Features>
        <Header.Features.TaskListButton />
        <Header.Features.AccessibilityButton />
        <Header.Features.NotificationButton />
        <Header.Features.AjustesButton />
      </Header.Features>
      <Header.FeaturesContent>
        <Header.FeaturesContent.TaskListContent />
        <Header.FeaturesContent.AccessibilityContent />
        <Header.FeaturesContent.EloNotificationContent />
        <Header.FeaturesContent.AjustesContent />
      </Header.FeaturesContent>
    </Header>
  );
};

export default AppHeader;
